<template>
<div id="app" class="d-flex align-items-stretch align-content-stretch flex-column flex-lg-row">
      <Menu></Menu>
      <div id="content" class="mb-5 pb-5 mb-lg-1 pb-lg-1">
            <router-view />
            <div id="bottom" class="d-none d-lg-block mt-5">
                  <Footer></Footer>
            </div>
      </div>
</div>
</template>

<script>
import Footer from '@/components/common/Footer';
import Menu from "@/components/common/Menu.vue";

export default {
      name: 'App',
      components: {
            Menu,
            Footer
      },
      metaInfo: {

      },
      data() {
            return {
                  initialized: false
            }
      },
      computed: {
            currentUser() {
                  return this.$store.state.auth.user;
            },
            hasTournament() {
                  return this.$functions.hasTournament();
            }
      },
      methods: {
            CheckLogin() {                  
                  if (this.currentUser) {
                        this.$store.dispatch("profileg/checkUserLogin").then((response) => {
                              if (response) {
                                    if (response.data.status == "update") {
                                          localStorage.setItem('user', JSON.stringify(response.data.user));
                                    }
                                    // var self = this;
                                    // var left = this.TimeLeftToken();
                                    //setTimeout(self.CheckLogin, left / 2); // återstående / 2
                              }
                        }).catch(() => {
                              this.logout();
                        });
                  }
                  else {
                        this.logout();
                  }
            },
            TimeLeftToken() {
                  var rawtoken = this.$functions.getToken();
                  var token = this.$functions.decodeToken(rawtoken);
                  var exp = new Date(token.exp * 1000);
                  var now = new Date();
                  var ms_left = exp - now;
                  return ms_left;
            },
            Init() {

            },
            logout(){
                  this.$store.dispatch("auth/logout");
                  this.$router.push('/loggain');
            }
      },
      watch: {
            currentUser() {
                  this.CheckLogin();
            }
      },
      mounted() {
            this.CheckLogin();
            this.$store.dispatch("tipsg/getAnswered");
      },
      beforeCreate() {
            this.$store.dispatch("stats/getStats", false);
            this.$store.dispatch("tournamentsg/getTournament");
            this.$store.dispatch("teams/getTeams");
            this.$store.dispatch("gbookg/getUserReadCount");
      }
};
</script>
