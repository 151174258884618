<template>
<div id="menu" class="mb-2">
    <div id="menu-bar" class="d-flex justify-content-lg-center align-items-center">
        <div class="mt-0 mb-0 mt-lg-3 mb-lg-3 p-1 p-lg-0 animate__bounceIn animate__animated d-none d-lg-block">
            <div v-if="currentTournament" class="hover-menu-icon p-2" @click="GoTo('/turneringar')">
                <div v-if="!tournamentloading">
                    <b-img :class="{ 'animate__flip' : avatar_flip }" class="tournament-logo animate__animated animate__faster" center :src="tournamentlogo()"></b-img>
                    <div class="text-center d-none d-lg-block">
                        {{tournament.name}}
                    </div>
                </div>
            </div>
            <div v-else>
                <router-link class="btn btn-outline-primary" to="/turneringar">Välj turnering</router-link>
            </div>
        </div>
        <div class="d-block d-lg-none w-100 p-1 mb-2">
            <b-row no-gutters class="d-flex align-items-center justify-content-between">
                <b-col class="text-center" @click="GoTo('/turneringar')">
                    <b-img :class="{'animate__animated animate__rubberBand' : isSelected('/turneringar', true)}" :src="tournamentlogo()" width="auto" height="35"></b-img>
                </b-col>
                <b-col class="text-center" >
                    <b-dropdown dropup text="Tipsa" variant="outline" no-caret>
                        <template #button-content>
                              <div class="d-inline text-center">
                              <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/tipsa', true)}" :src="svg('menu/dribbble.svg')" width="24" height="24" />
                              <br>
                              <span class="font-smaller">Tipsa</span>                                                                                          
                              </div>                        
                        </template>
                        <b-dropdown-item href="/tipsa/matcher">Matcher <b-badge v-if="unansweredtips > 0" pill variant="danger">{{unansweredtips}}</b-badge></b-dropdown-item>
                        <b-dropdown-item href="/tipsa/fragor">Frågor <b-badge v-if="unansweredquestions > 0" pill variant="danger">{{unansweredquestions}}</b-badge></b-dropdown-item>
                    </b-dropdown>                    
                </b-col>
                <b-col class="text-center" >
                 <b-dropdown dropup text="Visa" variant="outline" no-caret>
                        <template #button-content>
                              <div class="d-inline text-center">
                              <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/tipsa', true)}" :src="svg('menu/eye.svg')" width="24" height="24" />
                              <br>
                              <span class="font-smaller">Visa</span>                                                                                          
                              </div>                        
                        </template>
                        <b-dropdown-item href="/visa/matcher">Matcher</b-dropdown-item>
                        <b-dropdown-item href="/visa/fragor">Frågor</b-dropdown-item>
                    </b-dropdown>                    
                </b-col>                
                <b-col class="text-center" @click="GoTo('/tipsligan')">
                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/tipsligan', true)}" :src="this.$images.svg('menu/award.svg')" width="24" height="24" />
                    <br><span class="font-smaller">Tipsligan</span>
                </b-col>
                <b-col class="text-center d-none d-sm-block" @click="GoTo('/gastbok')">
                    <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/gastbok', true)}" :src="this.$images.svg('menu/message-circle.svg')" width="24" height="24" />
                    <br><span class="font-smaller">Gästbok</span>
                </b-col>
                <b-col class="text-center">
                    <inline-svg v-if="!visible" v-on:click="showMenu()" :src="this.$images.svg('menu/menu.svg')" width="24" height="24" />
                    <span v-if="!visible" class="font-smaller d-block">Meny</span>
                    <inline-svg v-if="visible" v-on:click="closeMenu()" :src="this.$images.svg('menu/x.svg')" width="24" height="24" />
                    <span v-if="visible" class="font-smaller d-block">Stäng</span>
                </b-col>
            </b-row>
        </div>
    </div>
    <b-list-group id="menu-nav" v-bind:class="{ visible: visible }">

        <b-list-group-item v-if="isAdministrator" v-b-toggle="'collapse-administrator'">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/admin', true)}" :src="this.$images.svg('menu/tool.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Administratör
                        <inline-svg :src="this.$images.svg(collapse.administrator? 'menu/chevron-up.svg': 'menu/chevron-down.svg')" width="14" height="18" />
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-collapse id="collapse-administrator" v-model="collapse.administrator" v-if="isAdministrator">
            <b-list-group-item class="sub-menu" :active="isSelected('/administrator/anvandare', true)" :to="'/administrator/anvandare'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Hantera användare
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/administrator/turneringar', true)" :to="'/administrator/turneringar'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Hantera turneringar
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/administrator/loggar', true)" :to="'/administrator/loggar'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Visa loggar
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/administrator/email', true)" :to="'/administrator/email'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Skicka epost
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/administrator/sms', true)" :to="'/administrator/sms'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Skicka SMS
                    </b-row>
                </b-container>
            </b-list-group-item>
        </b-collapse>

        <b-list-group-item v-if="isTournamentAdmin" v-b-toggle="'collapse-admin'">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/admin', true)}" :src="this.$images.svg('menu/settings.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Admin
                        <inline-svg :src="this.$images.svg(collapse.admin? 'menu/chevron-up.svg': 'menu/chevron-down.svg')" width="14" height="18" />
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-collapse id="collapse-admin" v-model="collapse.admin" v-if="isTournamentAdmin">
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/turnering', true)" :to="'/admin/turnering'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Turnering
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/betalning', true)" :to="'/admin/betalning'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Betalning
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item v-if="isAdministrator" class="sub-menu" :active="isSelected('/admin/invite', true)" :to="'/admin/invite'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Bjud in
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/grupper', true)" :to="'/admin/grupper'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Grupper
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/matcher', true)" :to="'/admin/matcher'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Matcher
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/fragor', true)" :to="'/admin/fragor'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Frågor
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/resultat', true)" :to="'/admin/resultat'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Resultat
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item v-if="isAdministrator" class="sub-menu" :active="isSelected('/admin/svar', true)" :to="'/admin/svar'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Användarsvar
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/anvandare', true)" :to="'/admin/anvandare'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Användare
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/nyheter', true)" :to="'/admin/nyheter'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Nyheter
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/admin/vinnare', true)" :to="'/admin/vinnare'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Vinnare
                    </b-row>
                </b-container>
            </b-list-group-item>
        </b-collapse>

        <b-list-group-item v-if="currentTournament" :active="isSelected('/start') || isSelected('/') " to="/start" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/start')}" :src="this.$images.svg('menu/home.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Start
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item class="d-block d-lg-none" :active="isSelected('/turneringar')" to="/turneringar" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/start')}" :src="this.$images.svg('menu/pocket.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Turneringar
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item :active="isSelected('/profil')" to="/profil" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <b-img :class="{ 'animate__flip' : avatar_flip }" class="menu-avatar animate__animated animate__faster" rounded="circle" :src="avatar()"></b-img>
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Din profil
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item v-if="currentTournament" v-b-toggle="'collapse-tips'">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/tipsa', true)}" :src="this.$images.svg('menu/dribbble.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Tipsa
                        <inline-svg :src="this.$images.svg(collapse.tips? 'menu/chevron-up.svg': 'menu/chevron-down.svg')" width="14" height="18" />
                        <b-badge class="inner-badge" v-if="unanswered > 0" pill variant="danger">{{unanswered}}</b-badge>
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-collapse v-if="currentTournament" id="collapse-tips" v-model="collapse.tips">
            <b-list-group-item class="sub-menu" :active="isSelected('/tipsa/matcher', )" :to="'/tipsa/matcher'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3 sub-title position-relative">
                        Matcher
                        <b-badge v-if="unansweredtips > 0" pill variant="danger">{{unansweredtips}}</b-badge>
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/tipsa/fragor', true)" :to="'/tipsa/fragor'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3 sub-title position-relative">
                        Frågor
                        <b-badge v-if="unansweredquestions > 0" pill variant="danger">{{unansweredquestions}}</b-badge>
                    </b-row>
                </b-container>
            </b-list-group-item>

        </b-collapse>

        <b-list-group-item v-if="currentTournament" v-b-toggle="'collapse-viewtips'">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/visa', true)}" :src="this.$images.svg('menu/eye.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Visa
                        <inline-svg :src="this.$images.svg(collapse.viewtips? 'menu/chevron-up.svg': 'menu/chevron-down.svg')" width="14" height="18" />
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-collapse v-if="currentTournament" id="collapse-viewtips" v-model="collapse.viewtips">
            <b-list-group-item class="sub-menu" :active="isSelected('/visa/matcher', true)" :to="'/visa/matcher'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Matcher
                    </b-row>
                </b-container>
            </b-list-group-item>
            <b-list-group-item class="sub-menu" :active="isSelected('/visa/fragor', true)" :to="'/visa/fragor'" v-on:click="click()">
                <b-container>
                    <b-row class="justify-content-start align-items-center pl-3">
                        Frågor
                    </b-row>
                </b-container>
            </b-list-group-item>

        </b-collapse>

        <b-list-group-item v-if="currentTournament" :active="isSelected('/tipsligan')" to="/tipsligan" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/tipsligan')}" :src="this.$images.svg('menu/award.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Tipsligan
                        <b-badge v-if="!placementloading" title="Din placering" pill :variant="isSelected('/tipsligan')? 'dark': 'primary'">{{placement}}</b-badge>
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item v-if="currentTournament" :active="isSelected('/statistik')" to="/statistik" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/statistik')}" :src="this.$images.svg('menu/activity.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Statistik            
                        <b-badge title="Se statistik!" variant="success">NYHET!</b-badge>            
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item v-if="currentTournament" :active="isSelected('/vinster')" to="/vinster" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/vinster')}" :src="this.$images.svg('menu/dollar-sign.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Vinster
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item v-if="currentTournament" :active="isSelected('/gastbok')" to="/gastbok" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/gastbok')}" :src="this.$images.svg('menu/message-circle.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Gästbok
                        <b-badge v-if="!readcountloading && unreadgbook > 0" title="Olästa inlägg" pill :variant="isSelected('/gastbok')? 'dark': 'primary'">{{unreadgbook}}</b-badge>                        
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item v-if="currentTournament" :active="isSelected('/grupper')" to="/grupper" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/grupper')}" :src="this.$images.svg('menu/groups.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Grupper
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item v-if="currentTournament" :active="isSelected('/resultat')" to="/resultat" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/resultat')}" :src="this.$images.svg('menu/calendar.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Resultat
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item v-if="currentTournament" :active="isSelected('/deltagare', true)" to="/deltagare" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/deltagare', true)}" :src="this.$images.svg('menu/users.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Deltagare
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item style="cursor:pointer" v-if="currentUser" @click.prevent="logOut">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/loggaut')}" :src="this.$images.svg('menu/log-out.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Logga ut
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <div class="sidebar-footer position-fixed d-none d-md-block" style="bottom:15px">

        </div>

    </b-list-group>
</div>
</template>

<script>
export default {
    name: 'Menu',
    data() {
        return {
            visible: false,
            avatar_flip: false,
            collapse: {
                administrator: false,
                admin: false,
                tips: false,
                viewtips: false
            }
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        tournamentloading() {
            return this.$store.getters["tournamentsg/tournamentloading"];
        },
        tournament() {
            if (this.currentTournament)
                return this.$store.getters["tournamentsg/tournament"];
            return false;
        },
        currentTournament() {
            return this.$functions.hasTournament();
        },
        isAdministrator() {
            if (this.currentUser && this.currentUser.role) {
                return this.currentUser.role.role == "100";
            }
            return false;
        },
        isTournamentAdmin() {
            if (this.currentUser && this.currentUser.role) {
                var taccess = this.$functions.tokenTournamentAccess();
                return taccess >= 30;
            }
            return false;
        },
        placementloading() {
            return this.$store.getters["stats/loading"];
        },
        placement() {
            var data = this.$store.getters["stats/stats"];
            if (data) {
                var stats = data.stats.filter((row) => {
                    var ua = data.useraccess[row.userid];
                    if (ua && ua >= 20) {
                        return true;
                    }
                    return false;
                });

                var placements = this.$functions.fixPlacements(
                    stats,
                    "totalpoints",
                    "placement"
                );
                for (var i = 0; i < placements.length; i++)
                    if (placements[i].userid == this.currentUser.id)
                        return placements[i].placement;
                return "";
            }
            return "";
        },
        answeredloading() {
            return this.$store.getters["tipsg/answeredloading"];
        },
        unanswered() {
            return this.unansweredtips + this.unansweredquestions;
        },
        unansweredtips() {
            var data = this.$store.getters["tipsg/answered"];
            if (data && data.games) {
                return data.games.total - data.games.answered;
            }
            return 0;
        },
        unansweredquestions() {
            var data = this.$store.getters["tipsg/answered"];
            if (data && data.questions) {
                return data.questions.total - data.questions.answered;
            }
            return 0;
        },
        unreadgbook(){
            var data = this.$store.getters["gbookg/readcount"];
            if(!data)
                return 0;
            
            if(data.TotalCount && data.TotalRead)
            {
                return parseInt(data.TotalCount) - parseInt(data.TotalRead);
            }
            return 0;
        },
        readcountloading(){
            return this.$store.getters["gbookg/readcountloading"];
        }
    },
    watch: {},
    mounted() {
        // if (this.currentUser)
        //       this.$store.dispatch("cart/CheckCartDraft");
    },
    created() {
        this.checkCollapsed();
    },
    methods: {
        click() {
            this.visible = false;
            this.avatar_flip = true;
            var self = this;
            setTimeout(() => {
                self.avatar_flip = false;
            }, 1000);
        },
        isSelected(path, startswith) {
            if (startswith) {
                return this.$route.path.startsWith(path);
            } else {
                return path == this.$route.path;
            }
        },
        hasavatar() {
            if (this.currentUser.image)
                return true;
            return false;
        },
        avatar() {
            if (this.currentUser)
                return this.$images.userAvatar(this.currentUser.id, this.currentUser.imgversion);
            return "";
        },
        tournamentlogo() {
            if (this.tournament)
                return this.$images.tournamentImage(this.tournament.uuid, this.tournament.imgversion);
            return "";
        },
        svg(path){
            return this.$images.svg(path);
        },
        showMenu() {
            this.visible = true;
        },
        closeMenu() {
            this.visible = false;
        },
        logOut() {
            //this.$root.$emit('bv::toggle::collapse', 'collapse-admin');
            //this.$root.$emit('bv::toggle::collapse', 'collapse-my-account')
            this.collapse.admin = false;
            this.collapse.account = false;
            this.$store.dispatch('auth/logout');
            this.$router.push('/loggain');
        },
        UserInitials() {
            var fname = this.currentUser.firstname;
            var lname = this.currentUser.lastname;
            var initials = fname.substring(0, 1) + lname.substring(0, 1);
            return initials.toUpperCase();
        },
        GoTo(path) {
            this.click();
            this.$router.push(path);
        },
        checkCollapsed() {
            var adminp = "/admin";
            var myp = "/minsida";
            if (this.$router.history._startLocation.startsWith(adminp)) {
                this.collapse.admin = true;
            } else if (this.$router.history._startLocation.startsWith(myp)) {
                this.collapse.account = true;
            }
        }
    }
}
</script>
