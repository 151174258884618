<template>
<footer class="mt-5">
      <b-row no-gutters class="border-top text-center">
            <b-col cols="5">
                  @Copyright {{year}}
            </b-col>
            <b-col cols="2">
                  |
            </b-col>
            <b-col cols="5">
                  <a :href="url">{{name}}</a>
            </b-col>
      </b-row>
</footer>
</template>

<script>
export default {
      name: 'Footer',
      computed: {
            year() {
                  return new Date().getFullYear();
            },
            name() {
                  return process.env.VUE_APP_NAME;
            },
            url() {
                  return process.env.VUE_APP_URL;
            }
      }
};
</script>
